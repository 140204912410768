export const environment = {
    production: false,
    API_BASE: 'https://api.dev.portal.maximimpressions.com',
  // API_BASE: 'http://localhost:3000',


  cache: {
      maxSize: 5000,

      /**
       * Max Age
       *
       * @type string
       * @default 1h
       * @description TTL of the cache. Use the below format
       * 1d2h3m4s5u
       * - d - Days
       * - h - hours
       * - m - minutes
       * - s - seconds
       * - u - milliseconds
       */
      maxAge: '1d'
    },
  }


const firebaseConfig = {
  apiKey: "AIzaSyAESYgkNfeYaGo78L9VgFH3AtKFR2cIH_4",
  authDomain: "maxim-portal-5f121.firebaseapp.com",
  projectId: "maxim-portal-5f121",
  storageBucket: "maxim-portal-5f121.appspot.com",
  messagingSenderId: "327412822608",
  appId: "1:327412822608:web:972781b6ba0fb5b6fbd755",
  measurementId: "G-6NN0TWRNCG",
};
